<template>
  <main>
    <transition name="fade" appear>
      <Hero />
    </transition>
    <Projects />
    <!-- <Testimonies /> -->
    <Stats />
    <!-- <Contact /> -->
    <Footer />
    <!-- <div class="gotoTopButton">
      <button class="btn rounded-pill btn-dark" v-show="gotoTopState" @click="gotoTop">
        <span><i class="fa fa-arrow-up"></i></span>
      </button>
    </div> -->
  </main>
</template>

<script>
// @ is an alias to /src
import Projects from '@/components/homepage/projects/Projects.vue';
import Hero from '@/components/homepage/hero/Hero.vue';
// import Testimonies from '@/components/homepage/testimonies/Testimonies.vue'
// import Contact from '@/components/homepage/contact/Contact.vue';
import Footer from '@/components/homepage/footer/Footer.vue';
import Stats from '../components/homepage/stats/Stats.vue';

export default {
  name: 'Home',
  components: {
    Hero,
    Projects,
    // Testimonies,
    // Contact,
    Stats,
    Footer,
  },
  // methods: {
  //   gotoTop(){
  //     this.intervalId = setInterval(() => {
  //       if (window.pageYOffset === 0) {
  //         clearInterval(this.intervalId)
  //       }
  //       window.scroll(0, window.pageYOffset - 50)
  //     }, 20)
  //   },
  //   scrollListener: function (e) {
  //     this.visible = window.scrollY > 150
  //   }
  // },
  // mounted: function () {
  //   window.addEventListener('scroll', this.scrollListener)
  // },
  // beforeDestroy: function () {
  //   window.removeEventListener('scroll', this.scrollListener)
  // }
};
</script>

<style scoped>
.gotoTopButton {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 10px;
  margin-bottom: 10px;
}
.fade-enter-from {
  opacity: 0;
  transform: translateY(20px);
}
.fade-enter-to {
  opacity: 1;
  transform: translateY(0);
}
.fade-enter-active {
  transition: all 1.5s ease;
}
</style>
