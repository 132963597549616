<template>
  <main class="container">
    <transition name="fade" appear>
      <div class="row h-100">
        <div class="col-12 my-auto">
          <div class="text-center my-auto h-100">
            <h2 class="primaryColor">Page Not Found</h2>
            <h1 class="text-white statusCode">404</h1>
            <p class="text-white-50">
              Nothing was found for this location. Maybe start from the
              homepage.
            </p>
            <router-link
              to="/"
              class="btn rounded-pill primaryBgColor text-white px-3"
              >Homepage</router-link
            >
          </div>
        </div>
      </div>
    </transition>
  </main>
  <Footer />
</template>
<script>
import Footer from '../components/homepage/footer/Footer.vue';

export default {
  components: { Footer },
  name: 'PageNotFound',
};
</script>
<style scoped>
main {
  height: 100vh;
}
.statusCode {
  font-size: 10rem;
}
.gotoTopButton {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 10px;
  margin-bottom: 10px;
}
.fade-enter-from {
  opacity: 0;
  transform: translateY(20px);
}
.fade-enter-to {
  opacity: 1;
  transform: translateY(0);
}
.fade-enter-active {
  transition: all 1.5s ease;
}
</style>
