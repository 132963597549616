<template>
  <section class="container py-5 text-white" id="projectSection">
    <div class="row">
      <div class="col-12">
        <h4 class="text-center h4">Projects</h4>
        <hr class="topicLine" />
      </div>
    </div>
    <div class="row justify-content-center mt-4">
      <div
        v-for="project in projectList"
        :key="project"
        class="
          col-sm-12 col-md-4 col-lg-4 col-xl-4
          d-flex
          align-content-stretch
          mb-3 mb-md-4
        "
      >
        <Project :project="project" />
      </div>
    </div>
  </section>
</template>

<script>
import Project from './Project.vue';

export default {
  name: 'Projects',
  components: {
    Project,
  },
  data() {
    return {
      projectList: [
        {
          name: 'Kingsdelight Glory School',
          detail: 'School Website and School Portal',
          technologies: ['HTML', 'CSS', 'JavaScript', 'Bootstrap4', 'PHP'],
          type: 'fullstack',
          links: {
            source: '',
            doc: "",
            website: 'https://kingsdelightgloryschool.com',
          },
        },
        {
          name: 'Loan Application (Frontend only)',
          detail: 'Pay only 30% of your checkout and spread remaining balance repayment for up to 6 months.',
          technologies: ['ReactJS', 'Material UI'],
          type: 'frontend',
          links: {
            source: '',
            doc: '',
            website: 'https://loan-system.netlify.app',
          },
        },
        {
          name: 'Loan App API',
          detail: 'A full loan app application that enables a user request for loan and repay loans. Admin can approve, reject and disburse loans.',
          technologies: ['Node.js', 'Express.js', 'JavaScript', 'Bootstrap4'],
          type: 'backend',
          links: {
            source: '',
            doc: 'https://github.com/Gblack-Hub/loan-app-api/blob/main/README.md',
            website: 'https://gblack-loan-api.herokuapp.com/',
          },
        },
        {
          name: 'My Portfolio',
          detail: 'Know more about me.',
          technologies: ['Nuxtjs', 'Vuejs', 'Nodejs', 'Bootstrap4'],
          type: 'fullstack',
          links: {
            source: '',
            doc: '',
            website: 'https://gblack-portfolio.netlify.app',
          },
        },
        {
          name: 'Quikstays',
          detail: 'A per hour hotel booking system.',
          technologies: ['React.js', 'Redux', 'Material UI'],
          type: 'frontend',
          links: {
            source: '',
            doc: '',
            website: 'https://www.quikstays.com',
          },
        },
        {
          name: 'Medillery',
          detail: 'A freelancing platform for graphic designers.',
          technologies: ['React', 'Redux', 'Material UI', 'CSS'],
          type: 'frontend',
          links: {
            source: '',
            doc: '',
            website: 'https://www.medillery.com',
          },
        },
        {
          name: 'Dice',
          detail: 'A virtual gaming platform.',
          technologies: ['Vanilla JS', 'React', 'Bootstrap5', 'JQuery', 'CSS'],
          type: 'frontend',
          links: {
            source: '',
            doc: '',
            website: 'https://dice.ng',
          },
        },
        // {
        //   name: 'Student Attendance System',
        //   detail: 'Daily classroom attendance, time-table and more.',
        //   technologies: ['AngularJS', 'PHP', 'Bootstrap4'],
        //   type: 'fullstack',
        //   links: {
        //     source: '',
        //      doc: '',
        //     website: 'https://student-attendance-sys.herokuapp.com',
        //   },
        // },
        // {
        //   name: 'Online Banking System',
        //   detail: 'Simulate basic banking operations with this application.',
        //   technologies: ['JavaScript', 'PHP', 'Bootstrap4'],
        //   type: 'fullstack',
        //   links: {
        //     source: '',
        //     doc: '',
        //     website: 'http://sas.hstn.me',
        //   },
        // },
        {
          name: 'Big Cars Info Website',
          detail: 'View Cars, prices and related information.',
          technologies: ['Vuejs', 'Vuetify', 'SCSS'],
          type: 'fullstack',
          links: {
            source: 'https://github.com/Gblack-Hub/car-shop.git',
            doc: '',
            website: 'https://vue-cars-shop.netlify.app',
          },
        },
        // {	name: 'React Store',
        // 	detail: 'A mini e-commerce store.',
        // 	technologies: ['Reactjs', 'PHP', 'Bootstrap4'],
        //	type: "fullstack",
        // 	links: {
        // 		source: '#',
        //    doc: '',
        // 		website: 'https://online-banking-sys.herokuapp.com/login.php'
        // 	}
        // }
      ],
    };
  },
};
</script>

<style>
</style>