<template>
  <div
    class="
      heroBackgroundSection
      d-flex
      justify-content-center
      align-items-center
    "
  >
    <div class="container">
      <div class="row text-white">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
          <div class="mb-2 text-center">
            <div class="heroImageContainer mx-auto">
              <img
                class="fitImageCircle"
                alt="hero-image"
                src="../../../assets/images/hero.jpg"
              />
            </div>
          </div>
          <h4 class="h5">
            <span class="text-white-50">I am</span> Olawale Oladiran
          </h4>
          <div class="text-white-50 mb-2">
            A Full Stack Developer who specializes in building fast,
            beautiful and responsive websites and web applications.
          </div>
          <span class="text-white-50"
            >Got Anything?
            <a
              class="primaryColor text-decoration-none"
              href="mailto:oladiranwale@gmail.com?subject=I need a website,"
              >Contact me</a
            ></span
          >
        </div>
        <div
          class="
            col-sm-12 col-md-12 col-lg-12 col-xl-12
            my-auto
            text-center
            pt-5
          "
        >
          <a
            class="
              btn btn-outline-light
              primaryColorBorder
              py-1
              rounded-pill
              text-capitalize
              mx-2
              mb-2
            "
            href="#projectSection"
          >
            <small class="mr-2 pl-2">My Projects</small>
            <span
              ><i class="fa fa-long-arrow-right primaryColor pr-2"></i
            ></span>
          </a>
          <!-- <a
            class="
              btn
              primaryBgColor
              py-1
              rounded-pill
              text-capitalize
              mx-2
              mb-2
            "
            href="#contactSection"
          >
            <small class="px-2 text-white">Hire me</small>
          </a> -->

          <!-- <router-link v-for="link in heroLinks" :key="link" class="btn btn-outline-light py-1 rounded-pill text-capitalize mx-2 mb-2" :to="link.link">
						<small>{{link.title}}</small>
					</router-link> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero',
  data() {
    return {
      heroLinks: [
        // { title: 'projects', link: '/projects' },
        // { title: 'Testimonies', link: '/testimonies' },
        // { title: 'Explore', link: '/resume' },
        { title: 'Résumé', link: '/resume' },
        // { title: 'Contact Me', link: '/contact' },
      ],
    };
  },
};
</script>

<style scoped>
/* .heroBackgroundSection {
		background: url('../../assets/images/hero-bg.jpg');
	} */
.heroImageContainer {
  height: 150px;
  width: 150px;
  border: 1px solid #16161a;
  border-radius: 50%;
}
</style>