<template>
  <div class="card darkContainerBox projectCard text-white w-100">
    <!-- <img src="../../../assets/images/img.jpg" class="card-img-top" :alt="project.name" /> -->
    <div class="card-body">
      <a
        :href="project.links.website"
        target="_blank"
        class="text-decoration-none card-title text-white lead mb-1"
        >{{ project.name }}
      </a>
      <p class="card-text small text-white-50">{{ project.detail }}</p>
      <div class="small">Technologies:</div>
      <span
        class="small text-white-50"
        v-for="(tech, index) in project.technologies"
        :key="tech"
        >{{ tech }}{{ index + 1 === project.technologies.length ? '' : ', ' }}
      </span>
      <div class="mt-3 d-flex justify-content-between align-items-center small">
        <div v-if="project.links.source != ''">
          <a
            :href="project.links.source"
            target="_blank"
            class="text-decoration-none text-white-50 text-capitalize"
          >
            <span><i class="fa fa-github"></i></span> source
          </a>
        </div>
        <div v-if="project.links.doc != ''">
          <a
            :href="project.links.doc"
            target="_blank"
            class="text-decoration-none text-white-50 text-capitalize"
          >
            <span><i class="fa fa-github"></i></span> doc
          </a>
        </div>
        <div>
          <a
            v-if="project.links.website != ''"
            :href="project.links.website"
            target="_blank"
            class="text-decoration-none text-white-50 text-capitalize"
          >
            <span><i class="fa fa-globe"></i></span> visit
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Project',
  props: {
    project: Object,
  },
};
</script>

<style>
</style>