<template>
  <section
    class="container-fluid py-2 mt-3 text-white darkContainerBox"
    id="statsSection"
  >
    <div class="row justify-content-center mt-4">
      <div
        v-for="stat in statsList"
        :key="stat"
        class="
          col-sm-12 col-md-4 col-lg-4 col-xl-4
          d-flex
          align-content-stretch
          mb-3
        "
      >
        <Stat :stat="stat" />
      </div>
    </div>
  </section>
</template>

<script>
import Stat from './Stat.vue';

export default {
  name: 'Stats',
  components: {
    Stat,
  },
  data() {
    return {
      statsList: [
        {
          count: 2,
          name: 'Projects Completed',
        },
        {
          count: 1,
          name: 'Happy Client(s)',
        },
      ],
    };
  },
};
</script>

<style>
</style>