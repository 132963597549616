<template>
  <div class="card darkContainerBox text-white w-100 text-center">
    <!-- <img src="../../../assets/images/img.jpg" class="card-img-top" :alt="project.name" /> -->
    <div class="card-body">
      <div class="statCountStyle primaryColor">{{ stat.count }}</div>
      <p class="text-white-50">{{ stat.name }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Stat',
  props: {
    stat: Object,
  },
};
</script>

<style scoped>
.statCountStyle {
  font-size: 1.8rem;
  font-weight: 300;
}
</style>