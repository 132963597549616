<template>
  <transition name="navbar-fade" appear>
    <Navbar />
  </transition>
  <router-view/>
</template>

<script>
  import Navbar from '@/components/homepage/navbar/Navbar.vue'

  export default {
    components: {
      Navbar,
    }
  }
</script>

<style scoped>
  .navbar-fade-enter-from {
    opacity: 0;
    transform: translateY(-60px);
  }
  .navbar-fade-enter-to {
    opacity: 1;
    transform: translateY(0);
  }
  .navbar-fade-enter-active {
    transition: all 1.5s ease;
  }
</style>