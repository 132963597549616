<template>
  <div class="text-center bg-black pt-4 pb-2">
    <div class="container">
      <div class="row py-3">
        <div class="col-12">
          <div class="row justify-content-center">
            <div
              v-for="item in socialMediaHandles"
              :key="item"
              class="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-2"
            >
              <div
                class="
                  d-flex
                  align-items-center
                  justify-content-center
                  text-white-50
                "
              >
                <a
                  :href="item.link"
                  target="_blank"
                  class="text-decoration-none text-white-50"
                >
                  <span class="mr-1 primaryColor"><i :class="item.icon"></i></span>
                  <span>{{ item.name }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 py-3 small text-muted">
          <span class="primaryColor">&copy;</span> {{ currentYear }} G'Black, All Rights Reserved
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      currentYear: new Date().getFullYear(),
      socialMediaHandles: [
        {
          name: 'LinkedIn',
          icon: 'fa fa-linkedin',
          link: 'https://www.linkedin.com/in/olawale-oladiran',
        },
        {
          name: 'Github',
          icon: 'fa fa-github',
          link: 'https://github.com/Gblack-Hub',
        },
      ],
    };
  },
};
</script>